import React from 'react'
import { isNotNull } from '../../../utils/nonNull'
import { html } from '../../../utils/htmlParser'
import { createPortal } from 'react-dom'
import { GlobalAlert } from '../../GlobalAlert/globalAlert'
import { AlertQuery } from '../../../pages/api/__generated__/alerts'
import { WebchatButton } from '../../webchatButton/WebchatButton'

export type AlertProps = AlertQuery

export const AlertsFragment: React.FC<AlertProps> = ({ globalSet, entries }) => {
  const portal = typeof document !== 'undefined' ? document.querySelector('.alert-container') : null

  const alertContainers = (
    <>
      {typeof window !== 'undefined' &&
    <>
      <WebchatButton/>
      {globalSet?.__typename === 'alertsTab_GlobalSet' && globalSet?.globalAlerts?.[0]?.cookieAlert &&
      <GlobalAlert buttonText="Accept" hashInfo={globalSet?.globalAlerts?.[0]?.cookieAlert} type={'dark'}>
        {html(globalSet?.globalAlerts?.[0]?.cookieAlert)}
      </GlobalAlert>
      }
      {isNotNull(entries) &&
      entries.map((item) => ( item?.__typename === 'alert_default_Entry' && item?.status === 'live' && item.alert?.[0]?.alertContent &&
        <GlobalAlert hashInfo={item?.alert?.[0]?.alertContent} type={item?.alert?.[0]?.style === 'primary' || item.alert?.[0]?.style === 'pleaseSelect' ? 'primary' : (item.alert?.[0]?.style === 'dark' ? 'dark' : 'light')}>
          {html(item.alert?.[0]?.alertContent)}
        </GlobalAlert>
      ))
      }
    </>
      }
    </>
  )

  return portal ? createPortal(alertContainers, portal) : alertContainers
}
